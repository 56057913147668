import _defineProperty from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
/**
 * @file Manages the configs of site generator.
 * @author kkumari
 */

var siteGenerator = {
  yextLocationsSearchEndpoint: 'geosearchlocation',
  yextLocationsEndpoint: 'locations'
};
var availableThemes = [{
  themeName: 'ModernV1'
}, {
  themeName: 'ModernV2'
}, {
  themeName: 'ModernV3'
}, {
  themeName: 'EarthyV1'
}, {
  themeName: 'EarthyV2'
}, {
  themeName: 'EarthyV3'
}, {
  themeName: 'PlayfulV1'
}, {
  themeName: 'PlayfulV2'
}, {
  themeName: 'PlayfulV3'
}, {
  themeName: 'PetSuites'
}, {
  themeName: 'PreciseV1'
}, {
  themeName: 'PreciseV2'
}, {
  themeName: 'Corporate'
}];
export var weekDayNames = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday'
};
var srcSetVariations = {
  desktopL: 1600,
  desktop: 1366,
  tabletL: 1024,
  tabletP: 768,
  mobile: 375
};
var variations = {
  variationA: 'Variation-A',
  variationB: 'Variation-B',
  variationC: 'Variation-C',
  variationGallery: 'Variation-Gallery-Modal'
};
var API = {
  googleMap: {
    url: 'https://www.google.com/maps/place/'
  },
  recaptcha: {
    url: 'https://www.google.com/recaptcha/api.js?render='
  }
};
var pageName = {
  staff: 'staff',
  services: 'services'
};
var fontColor = {
  light: 'Light',
  dark: 'Dark',
  black: '#000'
};
var analytics = {
  analyticsType: {
    addressBox: 'address-box',
    downloadInfoCTA: 'cta-download-info',
    tileCTA: 'cta-3-tile',
    sectionCTA: 'cta-3-tile',
    promoBannerCTA: 'cta-promo-banner'
  },
  analyticsValue: {}
};
export var LOCATION_RESULT_PAGE_SIZE = 10;
export var linkTargetWindow = {
  samewindow: 'Open in the same page',
  newWindow: 'Open in a new page'
};
export var BUSINESS_TYPES = {
  GENERAL_PRACTICE: 'General Practice',
  PET_RESORTS_BARK_SIDE: 'Pet Resorts - Bark Side',
  PET_RESORTS_PETSUITES: 'Pet Resorts - PetSuites',
  SPECIALTY_ER: 'Specialty/ER',
  SPECIALTY: 'Specialty',
  RESORT: 'Resort',
  EQUINE: 'Equine',
  MASTER_BRAND: 'MasterBrand',
  ASSOCIATION_PORTAL: 'Association Portal',
  CORPORATE: 'Corporate'
};
export var LOCATION_BUSINESS_TYPES = {
  GENERAL_PRACTICE: {
    label: 'General Practice',
    value: 'GENERAL_PRACTICE'
  },
  PET_RESORTS_BARK_SIDE: {
    label: 'Pet Resorts - Bark Side',
    value: 'PET_RESORTS_BARK_SIDE'
  },
  PET_RESORTS_PETSUITES: {
    label: 'Pet Resorts - PetSuites',
    value: 'PET_RESORTS_PETSUITES'
  },
  SPECIALTY_ER: {
    label: 'Specialty/ER',
    value: 'SPECIALTY_ER'
  },
  'SPECIALTY_+_ER': {
    label: 'Specialty',
    value: 'SPECIALTY_+_ER'
  },
  PET_RESORTS: {
    label: 'Resort',
    value: 'PET_RESORTS'
  },
  EQUINE: {
    label: 'Equine',
    value: 'EQUINE'
  }
};
export var YOUTUBE_URL = 'https://www.youtube.com/embed/';
export var YOUTUBE_URL_IFRAME_API = 'https://www.youtube.com/iframe_api';
export var siteLinks = {
  back: 'Back'
};
export var socialURLs = {
  FACEBOOK: 'facebookUrl',
  LINKEDIN: 'linkedInUrl',
  TWITTER: 'twitterUrl',
  YOUTUBE: 'youTubeUrl',
  INSTAGRAM: 'instagramUrl'
};
export var siteConstants = {
  RICH_TEXT: 'RichText'
};
export var imageOptimisation = {
  params: 'fit=fill&fm=webp',
  modalParams: 'fit=pad&fm=webp',
  transparentBg: 'fit=fill',
  logo: 'fit=pad&fm=webp'
};
export var mediaContentType = {
  IMAGE: 'ContentfulImage',
  VIDEO: 'ContentfulVideo'
};
export var googleTagManager = {
  // use GTM-P7HHSXF for UAT
  id: process.env.NEXT_PUBLIC_CONTENTFUL_API_ENVIRONMENT === 'master' && process.env.NODE_ENV === 'production' ? 'GTM-KH882W6' : 'GTM-P7HHSXF',
  mainScriptSrc: 'https://www.googletagmanager.com/gtm.js',
  noScriptSrc: 'https://www.googletagmanager.com/ns.html?id='
};
export var formsCaptchaActions = {
  contactFormNVA: 'contactUsNVA',
  contactForm: 'contactUs',
  bookReservationForm: 'bookReservation'
};
export var THEME_CONST = {
  LIGHT: 'light',
  DARK: 'dark'
};
export var USstates = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};
export var HEADER_PARENTS = {
  DESKTOP_GRID: 'nva-header__wrapper--desktop nva-header__grid',
  MOBILE_GRID: 'nva-header__wrapper--mobile nva-header__grid',
  MOBILE_MENU_TOP: 'nva-header__mobile-menu-top',
  MOBILE_MENU_GRID: 'nva-header__mobile-menu-grid'
};
export var BROWSER = {
  FIREFOX: 'Mozilla Firefox',
  SAMSUNG: 'Samsung Browser',
  OPERA: 'Opera',
  EDGE_LEGACY: 'Microsoft Edge (Legacy)',
  EDGE: 'Microsoft Edge (Chromium)',
  CHROME: 'Google Chrome or Chromium',
  SAFARI: 'Apple Safari'
};
var constants = _objectSpread({
  siteGenerator: siteGenerator,
  srcSetVariations: srcSetVariations,
  variations: variations,
  API: API,
  availableThemes: availableThemes,
  analytics: analytics,
  pageName: pageName
}, fontColor);
export var staffBioAccreditationsSwitch = false; //TODO: Acreditations to be hidden on as per NVADDI-1859
export default constants;